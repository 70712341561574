module.exports = {
  email: "hello@rashaadrandall.com",

  socialMedia: [
    {
      name: "GitHub",
      url: "https://www.github.com/rrandall91",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/rashaad-randall"
    },
    {
      name: "Twitter",
      url: "https://www.twitter.com/RashaadRandall"
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/rashaadrandall"
    },
  ],

  colors: { navy: "#1A2A3C" }
};
