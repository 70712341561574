/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import SocialMedia from "./sections/social-media";

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <main>{children}</main>
        <footer className="py-3 text-center text-white text-sm">
          <SocialMedia />
          <p className="mb-0">
            Designed &amp; Built by
            {` `}
            <a href="https://www.github.com/rrandall91/rashaadrandall.com">Rashaad R. Randall</a>
            <br />
            <small>© {new Date().getFullYear()}</small>
          </p>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = { children: PropTypes.node.isRequired };

export default Layout;
