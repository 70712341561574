import React from "react";
import PropTypes from "prop-types";
import { GitHubIcon, LinkedInIcon, TwitterIcon, InstagramIcon } from ".";

const Icon = ({ name }) => {
  switch (name) {
  case "GitHub":
    return <GitHubIcon />;
  case "LinkedIn":
    return <LinkedInIcon />;
  case "Twitter":
    return <TwitterIcon />;
  case "Instagram":
    return <InstagramIcon />;
  default:
    return null;
  }
};

Icon.propTypes = { name: PropTypes.string.isRequired };

export default Icon;
