import React from "react";
import { Icon } from "../icons";
import { socialMedia } from "../../config";

const SocialMedia = () => {
  return (
    <div id="social-media" className="flex flex-wrap content-center justify-center">
      <ul>
        {
          socialMedia.map((platform) => {
            return (
              <li key={platform.name}>
                <a target="_blank" rel="noreferrer" href={platform.url}>
                  <Icon name={platform.name} />
                </a>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};

export default SocialMedia;
